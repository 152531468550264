import React from 'react';
import axios from 'axios';
import {v4 as uuid} from 'uuid'
// Define the URL of the Flask server
const flaskServerUrl = 'http://20.46.197.154:7000/chatbot';

const session_id = uuid()

async function askQuestion(question) {
  try {
      let req_message = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify({ session_id: session_id, question: question })
    }

      console.log(req_message)
      const response = await fetch(flaskServerUrl, req_message);

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      return responseData.response;
  } catch (error) {
      console.error('Error:', error);
      return 'Error occurred while processing your request.';
  }
}

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleMessage = (message) => {
    askQuestion(message)
    .then(answer => {
      const botMessage = createChatBotMessage(answer);

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
