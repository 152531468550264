import React from 'react'
import { Link } from 'react-router-dom'
import BannerImage from '../../sub-components/banner-image'

const Privacy = () => {
    return (
        <div className="bg-white">
            <BannerImage />
            <section className="relative mx-5 mt-5 xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)] overflow-hidden pb-10">
                <div>
                    <h4 className="text-sm font-light text-gray-600">
                        <Link to="/" className="hover:underline">Home</Link> / Privacy Policy
                    </h4>
                    <h1 className="text-2xl lg:text-3xl font-bold mt-5 text-gray-800">
                        Privacy Policy
                    </h1>

                    <div className="bg-gray-50 p-6 rounded-lg shadow-md mt-5">
                        <p className="text-sm text-gray-700 mb-4"><strong>Last updated:</strong> December 6, 2024</p>

                        <p className="text-gray-700 mb-4">
                            This Privacy Policy explains the policies and procedures regarding the collection, use, and disclosure of your information when you use the Service. It also explains your privacy rights and how the law protects you.
                        </p>

                        <p className="text-gray-700 mb-6">
                            By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.
                        </p>

                        <h2 className="text-xl font-semibold text-gray-800 mb-3">Interpretation and Definitions</h2>

                        <h3 className="text-lg font-medium text-gray-700 mb-2">Interpretation</h3>
                        <p className="text-gray-700 mb-4">
                            Words with initial capital letters have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in singular or plural.
                        </p>

                        <h3 className="text-lg font-medium text-gray-700 mb-2">Definitions</h3>
                        <p className="text-gray-700 mb-4">For the purposes of this Privacy Policy:</p>
                        <ul className="list-disc list-inside text-gray-700 space-y-2">
                            <li><strong>Account:</strong> A unique account created for you to access our Service or parts of our Service.</li>
                            <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party.</li>
                            <li><strong>Application:</strong> Refers to Meerana App, the software program provided by the Company.</li>
                            <li><strong>Company:</strong> (referred to as "the Company", "We", "Us", or "Our" in this Agreement) refers to Meerana Technologies, #1704, Blue Bay Tower, Business Bay, Dubai, UAE.</li>
                            <li><strong>Country:</strong> Refers to the United Arab Emirates.</li>
                            <li><strong>Device:</strong> Any device that can access the Service such as a computer, cellphone, or digital tablet.</li>
                            <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
                            <li><strong>Service:</strong> Refers to the Application.</li>
                            <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company.</li>
                            <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                            <li><strong>You:</strong> The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </ul>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Collecting and Using Your Personal Data</h2>

                        <h3 className="text-lg font-medium text-gray-700 mb-3">Types of Data Collected</h3>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Location Data</h4>
                        <p className="text-gray-700 mb-4">
                            We collect precise location data exclusively to enable accurate attendance tracking (clock-in and clock-out functionality). This data is used solely for internal purposes and is accessible only to authorized personnel. It is securely stored and retained only as long as necessary for its intended purpose.
                        </p>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                            <li><strong>Purpose:</strong> To ensure accurate attendance tracking and to provide location-based services.</li>
                            <li><strong>Retention:</strong> Location data is securely stored and retained only for as long as necessary.</li>
                            <li><strong>Security:</strong> The data is stored securely and deleted once no longer needed.</li>
                        </ul>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Camera Permission</h4>
                        <p className="text-gray-700 mb-4">
                            We request access to your device's camera to capture and process images for Optical Character Recognition (OCR) functionality. The captured data is used exclusively for OCR processing and is not shared externally unless explicitly required for the app's functionality.
                        </p>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                            <li><strong>Purpose:</strong> To capture document images for OCR and facilitate document scanning within the app.</li>
                            <li><strong>Data Usage:</strong> The captured data is used only for OCR processing.</li>
                            <li><strong>Security:</strong> The data is processed securely and handled with appropriate security measures.</li>
                        </ul>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Microphone Permission</h4>
                        <p className="text-gray-700 mb-4">
                            We request access to your device's microphone to enable speech-to-text functionality, facilitating user interaction through voice commands. Audio input is processed in real-time for text conversion and is not stored unless explicitly needed for app functionality.
                        </p>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                            <li><strong>Purpose:</strong> To allow voice input for text conversion and enhance app usability.</li>
                            <li><strong>Data Usage:</strong> Audio input is processed in real-time for text conversion.</li>
                            <li><strong>Security:</strong> Audio data is handled securely and not shared with third parties.</li>
                        </ul>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Photo Library Access</h4>
                        <p className="text-gray-700 mb-4">
                            We request access to your device’s photo library to allow you to upload and manage photos, including profile pictures. The photos are used solely for the purpose of updating your profile within the app and are not shared with third parties.
                        </p>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                            <li><strong>Purpose:</strong> To allow you to upload and manage photos for profile management.</li>
                            <li><strong>Security:</strong> The photos are stored securely and are not shared with third parties.</li>
                        </ul>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Speech Recognition</h4>
                        <p className="text-gray-700 mb-4">
                            We request permission to use speech recognition to convert your speech to text. This data is processed locally and is sent to the server only for text conversion purposes, with appropriate security measures in place.
                        </p>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
                            <li><strong>Purpose:</strong> To convert your speech into text for interaction with the app.</li>
                            <li><strong>Data Usage:</strong> Speech data is processed locally and sent for text conversion.</li>
                            <li><strong>Security:</strong> Appropriate security measures are in place to protect the data.</li>
                        </ul>

                        <h4 className="text-md font-medium text-gray-700 mb-3">Explicit Consent</h4>
                        <p className="text-gray-700 mb-6">
                            We obtain your explicit consent before collecting or accessing sensitive data such as location, camera, microphone, photo library, and speech recognition access. You have the right to withdraw your consent at any time by adjusting your device settings or contacting us directly.
                        </p>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Data Retention and Security</h2>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Retention of Data</h4>
                        <p className="text-gray-700 mb-4">
                            Location, camera, microphone, and photo library data are securely stored and retained only as long as necessary to fulfill their intended purposes. Once no longer needed, data will be securely deleted.
                        </p>

                        <h4 className="text-md font-medium text-gray-700 mb-2">Security Measures</h4>
                        <p className="text-gray-700 mb-4">
                            We implement commercially acceptable security measures to protect your personal data. However, we cannot guarantee absolute security.
                        </p>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Sharing and Disclosure of Data</h2>

                        <p className="text-gray-700 mb-4">
                            We do not share, sell, or disclose your personal data to third parties unless required by law or explicitly stated in this policy. We may share anonymized or aggregated data for analytical purposes, but it will not identify you personally.
                        </p>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Your Rights</h2>

                        <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
                            <li>Access, update, or delete your personal data at any time.</li>
                            <li>Withdraw consent for location, camera, microphone, photo library, or speech recognition access at any time.</li>
                        </ul>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Changes to This Privacy Policy</h2>

                        <p className="text-gray-700 mb-4">
                            We may update this Privacy Policy from time to time. Any changes will be posted in the app and on our website. We encourage you to review this policy periodically to stay informed about how we protect your data.
                        </p>

                        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Contact Us</h2>

                        <p className="text-gray-700 mb-4">
                            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                        </p>
                        <p className="text-gray-700">
                            Meerana Technologies<br />
                            #1704, Blue Bay Tower, Business Bay, Dubai, UAE<br />
                            Email: info@meerana.ae<br />
                            {/* Phone: [Phone] */}
                        </p>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Privacy
